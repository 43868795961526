import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
	selector: 'app-date-range-picker',
	templateUrl: './date-range-picker.component.html',
	styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnDestroy {
	@Output() dateChanged = new EventEmitter<{ from: number; to: number }>();

	private sub = new Subscription();
	isValidStartDate = true;
	isValidEndDate = true;
	startDateValidationStr: string;
	endDateValidationStr: string;
	disableFrom = new Date(2023, 4, 24);
	disableTo = new Date();

	rangeStart: Date;
	rangeEnd: Date;

	apts: IAngularMyDpOptions;

	// onRangeSelected(range: [Date, Date]) {}

	setStart(d: string) {
		this.rangeStart = new Date(new Date(d).setHours(0, 0, 0, 0));

		if (!this.validDateRange()) {
			return;
		}
		this.dateChanged.emit({ from: this.rangeStart.getTime(), to: this.rangeEnd.getTime() });
	}

	setEind(d: string) {
		this.rangeEnd = new Date(new Date(d).setHours(23, 59, 59, 999));

		if (!this.validDateRange()) {
			return;
		}
		this.dateChanged.emit({ from: this.rangeStart.getTime(), to: this.rangeEnd.getTime() });
	}

	private timeZoneCorrection(d: Date) {
		return 60000 * d.getTimezoneOffset();
	}

	private isMoreThanOneWeekDifference(d1: Date, d2: Date): boolean {
		const weekMiliSeconds = 7 * 24 * 3600 * 1000;
		return Math.abs((d1.getTime() - d2.getTime()) / weekMiliSeconds) > 1;
	}

	public validDateRange(): boolean {
		if (
			this.rangeStart &&
			this.rangeEnd &&
			this.rangeEnd.getTime() > this.rangeStart.getTime() &&
			!this.isMoreThanOneWeekDifference(this.rangeStart, this.rangeEnd)
		) {
			// this.onRangeSelected([this.rangeStart, this.rangeEnd]);
			this.isValidStartDate = true;
			this.isValidEndDate = true;
			this.startDateValidationStr = '';
			this.endDateValidationStr = '';
			return true;
		} else {
			this.validationText();
			return false;
		}
	}

	validationText() {
		if (this.rangeEnd.getTime() < this.rangeStart.getTime()) {
			this.isValidStartDate = false;
			this.startDateValidationStr = 'Startdatum ongeldig';
		}
		if (this.rangeEnd.getTime() < this.rangeStart.getTime()) {
			this.isValidEndDate = false;
			this.endDateValidationStr = 'Einddatum ongeldig';
		} else if (this.isMoreThanOneWeekDifference(this.rangeStart, this.rangeEnd)) {
			this.isValidStartDate = false;
			this.isValidEndDate = false;
			this.startDateValidationStr = 'Te groot bereik';
		}
	}

	constructor() {}

	ngOnInit(): void {
		this.rangeStart = new Date(new Date().setHours(0, 0, 0, 0));
		this.rangeEnd = new Date(new Date().setHours(23, 59, 59, 999));
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}
}
