import { NgModule } from '@angular/core';
import { DashboardSearchPipe } from './dashboard-search.pipe';
import { DistanceToPipe } from './distance-to.pipe';

export const DECLARED_EXPORTS = [DistanceToPipe, DashboardSearchPipe];

@NgModule({
	declarations: [...DECLARED_EXPORTS],
	exports: DECLARED_EXPORTS,
})
export class SharedPipesModule {}
