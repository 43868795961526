import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFirestoreCollection } from '@angular/fire/firestore/public_api';
import { Sensor } from '@core/classes/sensor.class';
import { TiltMeter } from '@core/classes/tiltmeter.class';
import { Woning } from '@core/classes/woning.class';
import {
	MEETPUNTEN_COLLECTION,
	PROJECTS_COLLECTION,
	SENSOREN_COLLECTION,
	WONINGEN_COLLECTION,
	ALARM_WAARDEN,
	GEBRUIKERS_COLLECTION,
	DASHBOARD_COLLECTION,
	DASHBOARD__SETTINGS_COLLECTION,
} from 'app/config/database.config';
import { uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, timestamp } from 'rxjs/operators';
import {
	AlarmTresholds,
	TiltDashboard,
	TiltGebruiker,
	TiltMeetpunt,
	TiltProject,
	TiltSensor,
	TiltWoning,
} from 'shared';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
	providedIn: 'root',
})
export class DatabaseService {
	projectsAccessibleToUser$ = this.storage.useCacheStrategy(
		this.auth.uid$.pipe(
			switchMap(uid => {
				return this.valueChanges(
					this.db.collection<TiltProject>(PROJECTS_COLLECTION, q => q.where('uids', 'array-contains', uid))
				);
			})
		),
		'projecten_user'
	);

	woningenFirestoreViaProject$ = this.storage.useCacheStrategy(
		this.projectsAccessibleToUser$.pipe(
			switchMap(projects => {
				if (projects.length === 0) {
					return of([]);
				}
				return this.valueChanges(
					this.db.collection<TiltWoning>(WONINGEN_COLLECTION, q =>
						q.where(
							'project',
							'array-contains-any',
							projects.map(p => p.projectId)
						)
					)
				);
			})
		),
		'woningen_via_project'
	);

	woningenFirestoreViaUser$ = this.storage.useCacheStrategy(
		this.auth.uid$.pipe(
			switchMap(uid => {
				return this.valueChanges(
					this.db.collection<TiltWoning>(WONINGEN_COLLECTION, q => q.where('uids', 'array-contains', uid))
				);
			})
		),
		'woningen_via_user'
	);

	woningen$ = combineLatest([this.woningenFirestoreViaUser$, this.woningenFirestoreViaProject$]).pipe(
		debounceTime(250),
		map(([user, project]) => {
			const woningenDb = [...user, ...project];
			const woningen = woningenDb.filter(w => w.lat && w.lng).map(w => new Woning(w));
			return uniqBy(woningen, 'id') as Woning[];
		}),
		shareReplay(1)
	);

	dateRangeSubject$: BehaviorSubject<{ from: number; to: number }> = new BehaviorSubject<{ from: number; to: number }>({
		from: new Date().setHours(0, 0, 0, 0),
		to: new Date().setHours(23, 59, 59, 999),
	});
	dashboardRange$: Observable<{ from: number; to: number }> = this.dateRangeSubject$.asObservable();

	dashboardData$ = combineLatest([this.dashboardRange$]).pipe(
		switchMap(([currentRange]) => {
			const fromRange = currentRange.from;
			const toRange = currentRange.to;
			return combineLatest([
				this.valueChanges(
					this.db.collection<TiltDashboard>(DASHBOARD_COLLECTION, ref =>
						ref.where('timestamp', '>=', fromRange).where('timestamp', '<=', toRange).orderBy('timestamp', 'desc')
					)
				),
				this.dashboardRange$,
			]);
		}),
		map(([alarms, currentRange]) => {
			// console.log(JSON.stringify(alarms, null, 2));
			const countObj = {};
			const filteredAlarms: TiltDashboard[] = [];
			alarms.forEach(a => {
				if (!a.alarmDetails) {
					a.alarmDetails = [];
				}

				const exists = filteredAlarms.find(
					b =>
						b.gowaId === a.gowaId &&
						b.alarmLevel === a.alarmLevel &&
						new Date(a.timestamp).getHours() === new Date(b.timestamp).getHours() &&
						new Date(a.timestamp).getDate() === new Date(b.timestamp).getDate()
				);
				// if (!exists && a.timestamp > new Date().getTime() - rangeToUse * 3600 * 1000) {

				if (!exists) {
					filteredAlarms.unshift(a);
				}
			});

			filteredAlarms.forEach(a => {
				if (countObj[a.gowaId]) {
					if (countObj[a.gowaId][a.alarmLevel]) {
						countObj[a.gowaId][a.alarmLevel] = countObj[a.gowaId][a.alarmLevel] + 1;
					} else {
						countObj[a.gowaId][a.alarmLevel] = 1;
					}
				} else {
					countObj[a.gowaId] = { [a.alarmLevel]: 1 };
				}
			});

			filteredAlarms.map(a => (a.aantal = countObj[a.gowaId]));

			const sensorOrder = ['interventie', 'signaal', 'leeg', 'ok'];
			// filteredAlarms.map(alarm => ({
			// 	...alarm,
			// 	alarmDetails: (alarm.alarmDetails || [])
			// 		.sort((a, b) => sensorOrder.indexOf(a.alarmLevel) - sensorOrder.indexOf(b.alarmLevel))
			// 		.map(al => ({
			// 			...al,
			// 			alarmValue: al.alarmLevel === 'leeg' ? (al.alarmValue = '-') : al.alarmValue,
			// 		})),

			const res = uniqBy(
				filteredAlarms
					.sort((c, d) => d.timestamp - c.timestamp)
					.map(a => ({
						...a,
						numSensors: a.alarmDetails.length,
						numAlarmSensors: a.alarmDetails.filter(d => d.alarmLevel !== 'ok').length,
						alarmDetails: a.alarmDetails
							.filter(d => d.alarmLevel !== 'ok')
							.sort((e, f) => sensorOrder.indexOf(e.alarmLevel) - sensorOrder.indexOf(f.alarmLevel)),
					})),
				v => [v.gowaId, v.alarmLevel].join()
			) as TiltDashboard[];
			return res;
		}),
		distinctUntilChanged(),
		shareReplay(1)
	);

	dijkvakken$ = this.dashboardData$.pipe(
		map(alarmItems => {
			const res = alarmItems
				.filter(a => a.dijkvak)
				.map(a => a.dijkvak)
				.sort((a, b) => {
					const numberA = parseInt(a.slice(0, -1), 10);
					const numberB = parseInt(b.slice(0, -1), 10);

					if (numberA === numberB) {
						const letterA = a.slice(-1);
						const letterB = b.slice(-1);
						return letterA.localeCompare(letterB);
					}

					return numberA - numberB;
				});

			return [...new Set(res)];
		})
	);

	settings$ = of('').pipe(
		switchMap(profile => this.valueChanges(this.db.collection<any>('tiltsettings'))),
		map(e => e[0]),
		distinctUntilChanged(),
		shareReplay(1)
	);

	permissions$ = this.auth.userProfile$.pipe(
		switchMap(profile =>
			this.valueChanges(
				this.db.collection<TiltGebruiker>(GEBRUIKERS_COLLECTION, q => q.where('email', '==', profile.email))
			)
		),
		map(users => users[0].permissions)
	);

	meetpuntenVoorWoningId$ = (woningId: string) =>
		of(woningId).pipe(
			switchMap(id => {
				return this.getCollection(
					this.db.collection<TiltMeetpunt>(MEETPUNTEN_COLLECTION, q => q.where('woningId', '==', id))
				);
			}),
			map(meetpunten => meetpunten.map(m => new TiltMeter(m)).sort((a, b) => a.index - b.index)),
			shareReplay(1)
		);

	sensorenVoorWoningId$ = (meetpunt: TiltMeetpunt) =>
		of(meetpunt.sensorId).pipe(
			switchMap(id => {
				return this.getCollection(
					this.db.collection<TiltSensor>(SENSOREN_COLLECTION, q =>
						q.where('sensorId', '==', id).where('gowaId', '==', meetpunt.gowaId)
					)
				);
			}),
			map(sensoren => sensoren.map(s => new Sensor(s))[0]),
			shareReplay(1)
		);

	alarmWaardenVoorWoningId$ = (woningId: string) =>
		of(woningId).pipe(
			switchMap(id => {
				return this.getCollection(
					this.db.collection<AlarmTresholds>(ALARM_WAARDEN, q => q.where('woningId', '==', id))
				);
			}),
			shareReplay(1)
		);

	private valueChanges<T>(collection: AngularFirestoreCollection<T>) {
		return collection.valueChanges();
	}

	private getCollection<T>(collection: AngularFirestoreCollection<T>) {
		return collection.get().pipe(map(querySnapshot => querySnapshot.docs.map(doc => doc.data() as T)));
	}

	constructor(private auth: AuthService, private storage: StorageService, private db: AngularFirestore) {}
}
