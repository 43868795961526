<div class="d-flex">
	<div class="form-group mb-1 mr-1">
		<label for="startDate" class="fs-12 mb-0 ml-1">Startdatum</label>
		<input
			[min]="disableFrom | date: 'yyyy-MM-dd'"
			[max]="disableTo | date: 'yyyy-MM-dd'"
			type="date"
			id="startDate"
			value="{{ rangeStart | date: 'yyyy-MM-dd' }}"
			(blur)="setStart($event.target.value)"
			(keydown.enter)="setStart($event.target.value)"
			[ngClass]="isValidStartDate ? 'class-one' : 'class-two'"
			class="form-control form-control-sm fs-12"
		/>
		<div *ngIf="!isValidStartDate" class="invalid text-center">{{ startDateValidationStr }}</div>
	</div>

	<div class="form-group mb-1">
		<label for="endDate" class="fs-12 mb-0 ml-1">Einddatum</label>
		<input
			[min]="disableFrom | date: 'yyyy-MM-dd'"
			[max]="disableTo | date: 'yyyy-MM-dd'"
			type="date"
			id="endDate"
			value="{{ rangeEnd | date: 'yyyy-MM-dd' }}"
			(keydown.enter)="setEind($event.target.value)"
			(blur)="setEind($event.target.value)"
			[ngClass]="isValidEndDate ? 'class-one' : 'class-two'"
			class="form-control form-control-sm fs-12"
		/>
		<div *ngIf="!isValidEndDate" class="invalid text-center">{{ endDateValidationStr }}</div>
	</div>
</div>
