import { Pipe, PipeTransform } from '@angular/core';
import { TiltDashboard } from 'shared';

@Pipe({
	name: 'dashboardSearch',
	pure: false,
})
export class DashboardSearchPipe implements PipeTransform {
	private filterByProp(value: TiltDashboard, prop: string, searchVal: string, fullMatch = false) {
		if (searchVal === 'all') {
			return true;
		}

		if (fullMatch) {
			return value[prop].toLowerCase() === searchVal.toLowerCase();
		} else {
			return value[prop].toLowerCase().indexOf(searchVal.toLowerCase()) > -1;
		}
	}

	filterByPropInArray(value: TiltDashboard, prop: string, searchVals: string[]) {
		searchVals = searchVals.filter(x => x !== null);
		if (searchVals.length === 0) return true;
		return searchVals.includes(value[prop]);
	}

	transform(
		values: TiltDashboard[],
		filter: {
			sort: string | null;
			dijkvak: string | null;
			text: string | null;
			status: string | null;
			type: string | null;
		}
	) {
		if (filter.text) {
			filter.text = filter.text.toLowerCase();
			values = values.filter(
				a =>
					this.filterByProp(a, 'gowaId', filter.text!) ||
					this.filterByProp(a, 'plaats', filter.text!) ||
					this.filterByProp(a, 'straat', filter.text!) ||
					this.filterByProp(a, 'opmerking', filter.text!)
			);
		}

		if (filter.status) {
			values = values.filter(a => this.filterByProp(a, 'alarmLevel', filter.status!, true));
		}

		if (filter.type) {
			values = values.filter(a => this.filterByProp(a, 'alarmReason', filter.type!, true));
		}

		if (filter.dijkvak) {
			values = values.filter(a => this.filterByProp(a, 'dijkvak', filter.dijkvak!, true));
		}

		if (filter.sort) {
			switch (filter.sort) {
				case 'datumDesc':
					values = values.sort((a, b) => b.timestamp - a.timestamp);
					break;
				case 'datumAsc':
					values = values.sort((a, b) => a.timestamp - b.timestamp);
					break;
				case 'gowaIdDesc':
					values = values.sort((a, b) => b.gowaId.localeCompare(a.gowaId));
					break;
				case 'gowaIdAsc':
					values = values.sort((a, b) => a.gowaId.localeCompare(b.gowaId));
					break;
				case 'aantalAlarmenDesc':
					values = values.sort((a, b) => b.aantal[b.alarmLevel]! - a.aantal[a.alarmLevel]!);
					break;
				case 'aantalAlarmenAsc':
					values = values.sort((a, b) => a.aantal[a.alarmLevel]! - b.aantal[b.alarmLevel]!);
					break;
			}
		}
		return values;
	}
}
