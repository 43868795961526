<ng-container *ngIf="this.mapController.mapType.getValue() === 0" [ngSwitch]="mapTypeId">
	<ion-icon
		title=""
		tooltip="Huizen centreren"
		placement="left"
		class="enter-sattelite-view"
		*ngSwitchCase="'roadmap'"
		name="locate-outline"
		(click)="showAllHouses()"
		theme="light"
	></ion-icon>
	<ion-icon
		title=""
		*ngSwitchDefault
		tooltip="Huizen centreren"
		placement="left"
		class="enter-terrain-view"
		name="locate-outline"
		(click)="showAllHouses()"
		theme="light"
	></ion-icon>
</ng-container>
