import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NotificationModule } from 'shared-library';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { BottomSheetModule } from './bottom-sheet/bottom-sheet.module';
import { InputFieldComponent } from './form/input-field/input-field.component';
import { PasswordFieldComponent } from './form/password-field/password-field.component';
import { FullpageOverlayModule } from './fullpage-overlay/fullpage-overlay.module';
import { FullwidthButtonComponent } from './fullwidth-button/fullwidth-button.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { PulseComponent } from './pulse/pulse.component';
import { SensorDotComponent } from './sensor-dot/sensor-dot.component';
import { MapOverviewButtonComponent } from './map-overview-button/map-overview-button.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';

const DECLARED_EXPORTS = [
	SensorDotComponent,
	PulseComponent,
	FullwidthButtonComponent,
	AuthLayoutComponent,
	BackButtonComponent,
	MapOverviewButtonComponent,
	InputFieldComponent,
	PasswordFieldComponent,
	LoadingSpinnerComponent,
	DateRangePickerComponent,
];
@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [...DECLARED_EXPORTS],
	imports: [
		HttpClientModule,
		CommonModule,
		RouterModule,
		FormsModule,
		BottomSheetModule,
		ReactiveFormsModule,
		NotificationModule,
		SharedPipesModule,
		TooltipModule,
		FullpageOverlayModule,
	],
	exports: [
		...DECLARED_EXPORTS,
		HttpClientModule,
		CommonModule,
		RouterModule,
		FormsModule,
		BottomSheetModule,
		ReactiveFormsModule,
		NotificationModule,
		SharedPipesModule,
		TooltipModule,
		FullpageOverlayModule,
	],
})
export class SharedModule {}
