import { Component, Input, OnInit } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { MapController } from 'app/routes/map/map/map.controller';

@Component({
	selector: 'app-map-overview-button',
	templateUrl: './map-overview-button.component.html',
	styleUrls: ['./map-overview-button.component.scss'],
})
export class MapOverviewButtonComponent implements OnInit {
	@Input() map: GoogleMap;

	constructor(public mapController: MapController) {}

	showAllHouses() {
		this.mapController.resetOverview$.next(true);
	}

	get mapTypeId() {
		return this.map._googleMap.getMapTypeId();
	}

	ngOnInit(): void {}
}
