<app-navbar-route text="kaart" route="/map" icon="map"></app-navbar-route>

<!-- <app-navbar-route [disabled]="true" text="huis" route="/project" icon="home"></app-navbar-route> -->

<app-navbar-route
	*ngIf="(permissions$ | async)?.alarmDashboard"
	text="dashboard"
	route="/dashboard"
	icon="notifications"
></app-navbar-route>

<app-navbar-route text="zoek" route="/map/search" icon="search"></app-navbar-route>

<app-navbar-route text="profiel" route="/profile">
	<ng-container image>
		<div class="profile-image" *ngIf="user$ | async as user">
			<img *ngIf="user.photoURL; else noPhoto" [src]="user.photoURL" [alt]="user.displayName" />
			<ng-template #noPhoto>
				<div class="profile-image-placeholder"></div>
			</ng-template>
		</div>
	</ng-container>
</app-navbar-route>
