import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, shareReplay, tap } from 'rxjs/operators';
import { ErrorFromBackend, SensorValueChecked, TiltmeterRangeArgs, Zetting } from 'shared';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	// url = 'http://localhost:8080/api';
	url = environment.apiEndpoint;
	private WoningSensors$ = {} as { key: BehaviorSubject<any> };
	private RelatieveRotatie$ = {} as { key: BehaviorSubject<any> };

	public testAlarmen = {
		get: (woningId: string, sensorId: string, rangeStart: string, rangeEnd: string) => {
			return this.http.get<{ message: string; data: SensorValueChecked[] } | ErrorFromBackend | Zetting>(
				`${this.url}/alarmen`,
				{
					params: {
						woningId,
						sensorId,
						rangeStart,
						rangeEnd,
					},
				}
			);
		},
	};

	public tiltmeters = {
		range: ({ measurepoint, rangeStart, rangeEnd }: TiltmeterRangeArgs) => {
			return this.http.get<{ x: string; y: number }[]>(`${this.url}/tiltmeters/range`, {
				params: {
					measurepoint,
					rangeStart,
					rangeEnd,
				},
			});
		},
	};

	public rondrekening = {
		range: (woningId: string, rangeStart: string, rangeEnd: string) => {
			return this.http.get<any>(`${this.url}/rondrekening`, {
				params: {
					woningId,
					rangeStart,
					rangeEnd,
				},
			});
		},
	};

	public sensors = {
		woningSensors: ({ sensorId, rangeStart, rangeEnd }) => {
			return this.http.get<any>(`${this.url}/woning/sensors`, {
				params: {
					sensorId,
					rangeStart,
					rangeEnd,
				},
			});
		},
	};

	public relatieverotatie = {
		get: ({ sensorId, rangeStart, rangeEnd }) => {
			return this.http.get<any>(`${this.url}/relrotatie/`, {
				params: {
					sensorId,
					rangeStart,
					rangeEnd,
				},
			});
		},
	};

	public auth = {
		setCustomClaims: () => {
			return this.http.get(`${this.url}/auth/setToken`);
		},
		validateEmail: (email: string) => {
			return this.http.get<{ email: string; valid: boolean }>(`${this.url}/auth/validateEmail`, { params: { email } });
		},
	};

	public dashboard = {
		getTimeRange: () => {
			return this.http.get<number>(`${this.url}/dashboard/range`);
		},

		setFromDate: (from: Date) => {
			return this.http
				.post(
					`${this.url}/dashboard/fromrange`,
					{ from: from.getTime() },
					{ headers: { 'Content-Type': 'application/json' } }
				)
				.subscribe();
		},
		setToDate: (to: Date) => {
			return this.http
				.post(
					`${this.url}/dashboard/torange`,
					{ to: to.getTime() },
					{ headers: { 'Content-Type': 'application/json' } }
				)
				.subscribe();
		},

		setTimeRange: (numHours: number) => {
			return this.http
				.post(
					`${this.url}/dashboard/range`,
					{ numHours: Number(numHours) },
					{ headers: { 'Content-Type': 'application/json' } }
				)
				.subscribe();
		},
	};
	getRelatieveRotatie({ sensorId, rangeStart, rangeEnd }): Observable<any> {
		const key = btoa(sensorId + rangeStart + rangeEnd);

		if (!this.RelatieveRotatie$[key]) {
			this.RelatieveRotatie$[key] = new BehaviorSubject(null);

			this.http
				.get<any>(`${this.url}/relrotatie/`, {
					params: {
						sensorId,
						rangeStart,
						rangeEnd,
					},
				})
				.pipe(
					tap((data: any) => {
						return data;
					}),
					tap((data: any) => this.RelatieveRotatie$[key].next(data))
				)
				.subscribe();
		}
		return this.RelatieveRotatie$[key].asObservable();
	}
	getWoningSensors({ sensorId, rangeStart, rangeEnd }): Observable<any> {
		const key = btoa(sensorId + rangeStart + rangeEnd);

		if (!this.WoningSensors$[key]) {
			this.WoningSensors$[key] = new BehaviorSubject(null);

			this.http
				.get<any>(`${this.url}/woning/sensors`, {
					params: {
						sensorId,
						rangeStart,
						rangeEnd,
					},
				})
				.pipe(
					tap((data: any) => {
						return data;
					}),
					tap((data: any) => this.WoningSensors$[key].next(data))
				)
				.subscribe();
		}
		return this.WoningSensors$[key].asObservable();
	}
	constructor(private http: HttpClient) {}
}
