import { CustomGoogleMapMarker, markerOpts, markerType } from 'app/config/google-maps.config';

export class UserPositionMarker implements CustomGoogleMapMarker {
	id = 'userGeolocation';
	position = new google.maps.LatLng(0, 0);
	markerType = 'gebruiker' as markerType;
	options = markerOpts.user;

	constructor(latitude: number, longitude: number) {
		this.position = new google.maps.LatLng(latitude, longitude);
	}
}
