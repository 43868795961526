import { TiltMeetpunt } from 'shared';

export type markerType = 'woning' | 'meetpunt' | 'gebruiker' | 'sensor';
export enum AlarmLevel {
	'ok',
	'interventie',
	'signaal',
	'leeg',
	empty = '',
}

export interface CustomGoogleMapMarker {
	controleSensor1?: string;
	controleSensor2?: string;
	huisId?: string;
	id: string;
	index?: number;
	markerType: markerType;
	position: google.maps.LatLngLiteral | google.maps.LatLng;
	options: google.maps.MarkerOptions;
	alarmLevel?: AlarmLevel;
	metadata?: TiltMeetpunt;
}

export interface MapParams {
	huisId?: string;
	index?: string;
}

const flatePaleMapStyles: google.maps.MapTypeStyle[] = [
	{
		featureType: 'administrative',
		elementType: 'labels.text.fill',
		stylers: [
			{
				// color: '#6195a0',
			},
		],
	},
	{
		featureType: 'administrative.province',
		elementType: 'geometry.stroke',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'landscape',
		elementType: 'geometry',
		stylers: [
			{
				lightness: 0,
			},
			{
				saturation: 0,
			},
			{
				color: '#f5f5f2',
			},
			{
				gamma: 1,
			},
		],
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'all',
		stylers: [
			{
				lightness: -3,
			},
			{
				gamma: 1.0,
			},
		],
	},
	{
		featureType: 'landscape.natural.terrain',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#bae5ce',
			},
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [
			{
				saturation: -100,
			},
			{
				lightness: 45,
			},
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#fac9a9',
			},
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.text',
		stylers: [
			{
				color: '#4e4e4e',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#787878',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'transit.station.airport',
		elementType: 'labels.icon',
		stylers: [
			{
				hue: '#0a00ff',
			},
			{
				saturation: -77,
			},
			{
				gamma: 0.57,
			},
			{
				lightness: 0,
			},
		],
	},
	{
		featureType: 'transit.station.rail',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#43321e',
			},
		],
	},
	{
		featureType: 'transit.station.rail',
		elementType: 'labels.icon',
		stylers: [
			{
				hue: '#ff6c00',
			},
			{
				lightness: 4,
			},
			{
				gamma: 0.75,
			},
			{
				saturation: -68,
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [
			{
				// color: '#eaf6f8',
			},
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry.fill',
		stylers: [
			{
				// color: '#c7eced',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'labels.text.fill',
		stylers: [
			{
				lightness: -49,
			},
			{
				saturation: -53,
			},
			{
				gamma: 0.79,
			},
		],
	},
];

export const mapStyleOpts: google.maps.MapOptions = {
	disableDefaultUI: true,
	fullscreenControl: false,
	// We implement custom map controls.
	mapTypeControl: false,
	mapTypeId: 'roadmap',
	maxZoom: 25,
	styles: flatePaleMapStyles,
};

export const polygonOptions = {
	fillColor: '#34A853',
	fillOpacity: 0.5,
	strokeWeight: 0,
};

export const selectedMapMarker: google.maps.Symbol = {
	path: google.maps.SymbolPath.CIRCLE,
	scale: 10,
	fillColor: '#34A853',
	fillOpacity: 1,
	strokeColor: '#72C850',
	strokeOpacity: 1,
	strokeWeight: 2,
};

export const disabledMapMarker: google.maps.Symbol = {
	path: google.maps.SymbolPath.CIRCLE,
	scale: 10,
	fillColor: '#34A853',
	fillOpacity: 0.2,
	strokeColor: '#72C850',
	strokeOpacity: 0.2,
	strokeWeight: 2,
};

export const defaultMapMarker: google.maps.Symbol = {
	path: google.maps.SymbolPath.CIRCLE,
	scale: 10,
	fillColor: '#454D58',
	fillOpacity: 1,
	strokeColor: '#8B919A',
	strokeOpacity: 1,
	strokeWeight: 2,
};

export const userMapMarker: google.maps.Symbol = {
	path: google.maps.SymbolPath.CIRCLE,
	scale: 10,
	fillColor: '#1477F8',
	fillOpacity: 1,
	strokeColor: '#FFFFFF',
	strokeOpacity: 1,
	strokeWeight: 2,
};

export const markerOpts: { [key: string]: { draggable: boolean; icon: string | google.maps.Symbol } } = {
	user: {
		draggable: false,
		icon: userMapMarker,
	},
	woning: {
		draggable: false,
		icon: selectedMapMarker,
	},
	'sensor/default': {
		draggable: false,
		icon: defaultMapMarker,
	},
	'sensor/active': {
		draggable: false,
		icon: selectedMapMarker,
	},
	'sensor/filtered': {
		draggable: false,
		icon: disabledMapMarker,
	},
};

export const defaultMapZoom = 12;
