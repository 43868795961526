import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { DatabaseService } from '@core/services/database/database.service';
import { Observable } from 'rxjs';
import { UserPermissions } from 'shared';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	public permissions$: Observable<UserPermissions>;

	user$ = this.auth.userProfile$;

	constructor(private auth: AuthService, private dbService: DatabaseService) {}

	ngOnInit(): void {
		this.permissions$ = this.dbService.permissions$;
	}
}
