import { Injectable } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Sensor } from '@core/classes/sensor.class';
import { TiltMeter } from '@core/classes/tiltmeter.class';
import { Woning } from '@core/classes/woning.class';
import { notEmptyString } from '@core/functions/helpers';
import { DatabaseService } from '@core/services/database/database.service';
import { GeolocationService } from '@core/services/geolocation/geolocation.service';
import {
	LINECHART_BORDER_COLOR_INTERVENTION,
	LINECHART_BORDER_COLOR_NO_DATA,
	LINECHART_BORDER_COLOR_OK,
	LINECHART_BORDER_COLOR_SIGNAL,
} from 'app/config/chartjs/tilt-linechart.config';
import { REL_ROT_1_COLOR, REL_ROT_2_COLOR } from 'app/config/chartjs/tilt-sensorchart.config';
import { AlarmLevel, CustomGoogleMapMarker, polygonOptions } from 'app/config/google-maps.config';
import { BehaviorSubject, combineLatest, from, Observable, Subscription } from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	distinctUntilKeyChanged,
	filter,
	map,
	mergeMap,
	shareReplay,
	switchMap,
	take,
	tap,
	toArray,
	withLatestFrom,
} from 'rxjs/operators';
import { createBoundsFromLatLngArray } from './map.helpers';

export enum MapType {
	'Default' = 0,
	'Huis',
}

@Injectable({
	providedIn: 'root',
})
export class MapController {
	public mapInstance: GoogleMap | null;
	public mapType = new BehaviorSubject(MapType['Default']);
	private mapSubscription = new Subscription();
	private housePolygon = new google.maps.Polygon();
	private houseBounds = new BehaviorSubject<google.maps.LatLngBounds | null>(null);

	public mapCenterHouse = new BehaviorSubject<any>(null);
	private rememberedZoomHouse = new BehaviorSubject<number>(12);

	private mapCenterMap = new BehaviorSubject<any>(null);
	private rememberedZoomMap = new BehaviorSubject<number>(12);

	private overviewBounds = new BehaviorSubject<google.maps.LatLngBounds | null>(null);
	public activeZoom = new BehaviorSubject<Number>(12);

	private currentClick = 'overview';
	private previousClick = 'overview';
	private sameClick = true;

	public activeViewType = new BehaviorSubject(1);
	public drawPolygon = new BehaviorSubject(false);

	/*
	 * The current x and y arrows that are drawn on a map
	 */
	public mapArrows: google.maps.Marker[] = [];
	public relRotLines: google.maps.Polyline[] = [];

	/**
	 * An Observable streaming the House that is currently active.
	 */
	public activeHouse$: Observable<Woning>;
	/**
	 * An Observable streaming the Point that is currently active.
	 */
	public activePoint$: Observable<TiltMeter>;
	/**
	 * An Observable streaming the Point that is currently active.
	 */
	public activeSensor$: Observable<Sensor>;
	/**
	 * An Observable streaming all houses that we have access to.
	 * This reference changes upon things like addition / removal of permissions.
	 */
	public allHouses$ = this.database.woningen$.pipe(shareReplay(1));
	/**
	 * An Observable streaming all points that are linked to the currently active house.
	 */
	public pointsForActiveHouse$: Observable<TiltMeter[]>;

	/**
	 * An Observable streaming all sensors that are linked to the currently active house.
	 */
	public sensorsForActiveHouse$: Observable<Sensor[]>;

	/**
	 * Variable that proxies the user position marker provided by the geolocation Service.
	 * this is to keep service level out of the subscribing components, and provide a proxy via this service.
	 */
	public userPositionMarker$ = this.geolocationService.userPositionMarker$;
	/*
	 * Clear the current x and y arrows that are drawn on a map
	 */

	private _boundsLimit: google.maps.LatLngBounds;
	/**
	 * An Observable streaming all `CustomGoogleMapMarker` instances that should be visible on the map matching the current state of the controller.
	 */

	public resetOverview$ = new BehaviorSubject(false);

	public visibleMarkers$ = combineLatest([this.mapType, this.activeViewType, this.database.permissions$]).pipe(
		switchMap(([type, viewType, permissions]) => {
			switch (type) {
				case MapType['Huis']:
					this.markerSwitch('huis');
					if (viewType === 0) {
						return combineLatest([
							this.sensorsForActiveHouse$,
							this.activeSensor$.pipe(distinctUntilKeyChanged('id')),
							// this.activePoint$.pipe(distinctUntilKeyChanged('id')),
						]).pipe(
							debounceTime(50),
							map(([markers, activeMarker]) => {
								const rotSensors = this.getRelRotatieSensors(markers, activeMarker);

								this.drawRelatieveRotatatieArrows(markers, activeMarker);
								return this.setMarkerColorsOnMap(markers, activeMarker, permissions.visueelAlarm, rotSensors);
							})
						);
					} else if (viewType === 1 || viewType === 2) {
						return combineLatest([
							this.pointsForActiveHouse$,
							this.activePoint$.pipe(distinctUntilKeyChanged('id')),
						]).pipe(
							debounceTime(50),
							// TODO: Isolate side-effect.
							map(([markers, activeMarker]) => {
								const rotSensors = this.getRelRotatieSensors(markers, activeMarker);

								this.drawRelatieveRotatatieArrows(markers, activeMarker);

								return this.setMarkerColorsOnMap(markers, activeMarker, permissions.visueelAlarm, rotSensors);
							})
						);
					}
				/* falls through */
				case MapType['Default']:
				default:
					this.clearMapArrows(); // clear arrows when switching back to houses overview
					this.clearRelRotLines(); // clear lines when switching back to houses overview
					this.markerSwitch('overview');

					return this.allHouses$.pipe(
						map(houses => this.setMarkerColorsOnMap(houses, undefined, permissions.visueelAlarm))
					);
			}
		}),
		shareReplay(1)
	);

	public markerSwitch(newType: string) {
		this.previousClick = this.currentClick;
		this.currentClick = newType;

		this.sameClick = this.previousClick === this.currentClick;
		// console.log(this.previousClick + ' --> ' + this.currentClick, this.sameClick);
	}

	private clearMapArrows() {
		this.mapArrows.forEach(m => {
			m.setMap(null);
		});
	}

	private clearRelRotLines() {
		this.relRotLines.forEach(m => {
			m.setMap(null);
		});
		this.relRotLines = [];
	}

	private getRelRotatieSensors(markers: CustomGoogleMapMarker[], activeMarker: CustomGoogleMapMarker) {
		const relRotSensors: any = {};
		if (activeMarker) {
			if (activeMarker.controleSensor1) {
				if (activeMarker.metadata) {
					// =meetpunt
					const targetSensor = markers.find(
						m => m.metadata && m.metadata.sensorId === activeMarker.metadata!.controleSensor1
					);
					if (targetSensor) {
						relRotSensors[targetSensor.id] = 'R1';
					}
				} else {
					const targetSensor = markers.find(m => m.id === activeMarker.controleSensor1);
					if (targetSensor) {
						relRotSensors[targetSensor.id] = 'R1';
					}
				}
			}

			if (activeMarker.controleSensor2) {
				if (activeMarker.metadata) {
					// =meetpunt
					const targetSensor = markers.find(
						m => m.metadata && m.metadata.sensorId === activeMarker.metadata!.controleSensor2
					);
					if (targetSensor) {
						relRotSensors[targetSensor.id] = 'R2';
					}
				} else {
					const targetSensor = markers.find(m => m.id === activeMarker.controleSensor2);
					if (targetSensor) {
						relRotSensors[targetSensor.id] = 'R2';
					}
				}
			}
		}
		return relRotSensors;
	}

	private createRelRotatieLine(
		markers: CustomGoogleMapMarker[],
		activeMarker: CustomGoogleMapMarker,
		controleSensorId: string,
		colorHex: string
	) {
		let targetSensor = markers.find(m => m.id === controleSensorId);

		if (activeMarker.metadata) {
			targetSensor = markers.find(m => m.metadata?.sensorId === controleSensorId);
		}

		if (targetSensor) {
			const line = new google.maps.Polyline({
				path: [activeMarker.position as google.maps.LatLngLiteral, targetSensor.position as google.maps.LatLngLiteral],
				strokeColor: colorHex,
			});

			this.relRotLines.push(line);
		}
	}

	private drawRelatieveRotatatieArrows(markers: CustomGoogleMapMarker[], activeMarker?: CustomGoogleMapMarker) {
		this.clearRelRotLines();
		if (activeMarker) {
			if (activeMarker.controleSensor1) {
				this.createRelRotatieLine(markers, activeMarker, activeMarker.controleSensor1, REL_ROT_1_COLOR);
			}

			if (activeMarker.controleSensor2) {
				this.createRelRotatieLine(markers, activeMarker, activeMarker.controleSensor2, REL_ROT_2_COLOR);
			}

			this.relRotLines.forEach(a => {
				a.setMap(this.mapInstance && this.mapInstance._googleMap);
			});
		}
	}

	public drawPointArrows(sensor: Sensor, settings: { kleur_1_lijn: string; kleur_2_lijn: string }) {
		if (this.activeViewType.value !== 0) {
			// this should not happen
			return;
		}
		this.clearMapArrows();
		const arrowUp = 'M7 0 l45 0 l-0 0 l-8 -5 m8 5 l-8 5';
		const drawingLoc = sensor.position;
		const arrow1 = new google.maps.Marker({
			position: drawingLoc,
			icon: {
				path: arrowUp,
				scale: 2,
				rotation: sensor.sensorHoekX - 90,
				strokeColor: settings.kleur_1_lijn !== '#ffffff' ? settings.kleur_1_lijn : sensor.xLineColor,
				strokeWeight: 1,
				labelOrigin: new google.maps.Point(drawingLoc.lat(), drawingLoc.lng()),
			},
			label: { text: 'X', color: 'black', fontWeight: 'bold', fontSize: '16px' },
			clickable: false,
		});

		const arrow2 = new google.maps.Marker({
			position: drawingLoc,
			icon: {
				path: arrowUp,
				scale: 2,
				rotation: sensor.sensorHoekY - 90,
				strokeColor: settings.kleur_2_lijn !== '#ffffff' ? settings.kleur_2_lijn : sensor.xLineColor,
				strokeWeight: 1,
				labelOrigin: new google.maps.Point(drawingLoc.lat(), drawingLoc.lng()),
			},
			label: { text: 'Y', color: 'black', fontWeight: 'bold', fontSize: '16px' },
			clickable: false,
		});
		arrow1.setMap(this.mapInstance && this.mapInstance._googleMap);
		arrow2.setMap(this.mapInstance && this.mapInstance._googleMap);
		this.mapArrows.push(arrow1, arrow2);
	}

	private drawPolygonBetweenPoints(meetpuntMarkers: TiltMeter[]) {
		if (!this.drawPolygon.value) {
			return;
		}

		this.housePolygon.setMap(null);

		const pathToDraw = meetpuntMarkers
			.filter(marker => notEmptyString(marker.polygonIndex))
			.sort((a, b) => a.polygonIndex - b.polygonIndex)
			.map(marker => marker.position);

		this.housePolygon = new google.maps.Polygon({
			...polygonOptions,
			paths: pathToDraw,
		});
		this.housePolygon.setMap(this.mapInstance && this.mapInstance._googleMap);
	}

	/**
	 * applies a coloring function to points based on their alert- and active state.
	 * @param meetpuntMarkers the markers to apply colors to.
	 * @param activePoint the active marker, used to decide what color to render the marker in.
	 */
	setMarkerColorsOnMap(
		markers: CustomGoogleMapMarker[],
		activeMarker?: CustomGoogleMapMarker,
		showVisualWarning = false,
		relRotSensors?: any
	) {
		const getIcon = (marker: CustomGoogleMapMarker, isActive: boolean) => {
			let color = '#444444';
			if (showVisualWarning) {
				switch (marker.alarmLevel) {
					case AlarmLevel.ok:
						color = LINECHART_BORDER_COLOR_OK;
						break;
					case AlarmLevel.signaal:
						color = LINECHART_BORDER_COLOR_SIGNAL;
						break;
					case AlarmLevel.interventie:
						color = LINECHART_BORDER_COLOR_INTERVENTION;
						break;
					case AlarmLevel.leeg:
						color = LINECHART_BORDER_COLOR_NO_DATA;
						break;
					case AlarmLevel.empty:
					default:
						color = '#444444';
						break;
				}
			}

			// set opacity
			// color += isActive ? 'ff' : 'aa';

			let isAbsoluteRef = false;
			if ('index' in marker) {
				if (marker.index === 0 && this.activeViewType.value === 2) {
					isAbsoluteRef = true;
				}
			}
			let isRelRotRef = false;
			if (relRotSensors && relRotSensors[marker.id]) {
				isRelRotRef = true;
			}

			const witteCirkel = false;

			const svg = `
				<svg width="80" height="30" viewBox="-2 -2 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="15" cy="15" r="11" fill="${color}" stroke="white"/>
					${isActive ? `<circle cx="15" cy="15" r="14" stroke="${color}"/>` : ``}
					${
						isAbsoluteRef
							? `<circle cx="15" cy="15" r="11" stroke-width="3px" stroke="black"/> <circle cx="15" cy="15" r="4" fill="black"/>`
							: ``
					}
					${
						isRelRotRef
							? `<circle cx="-15" cy="10" r="12" fill="white"/>

							<text x="50%" y="45%" text-anchor="middle" font-size="12" stroke="black" fill="black" dx="-31px">${
								relRotSensors[marker.id]
							}</text>`
							: ``
					}
				</svg>`;
			return {
				url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
				size: new google.maps.Size(70, 30),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(40, 15),
			};
		};

		return markers.map(marker => ({
			...marker,
			options: {
				draggable: false,
				icon: getIcon(marker, !!activeMarker && marker.id === activeMarker.id),
			},
		}));
	}

	/**
	 * Updates the bounds of the `mapInstance` provided to the controller.
	 * @param markers what Markers are to be rendered.
	 * @param useUserPosition whether or not to expand the bounds to the user's geolocation.
	 */
	private async updateBoundsToMarkers(markers: CustomGoogleMapMarker[], reset?: boolean) {
		// console.log('update', this.mapCenterMap.value);

		if (this.mapInstance) {
			if (!this.mapCenterMap.value || this.mapType.value === MapType['Huis'] || reset) {
				this.rememberOverviewCurrentBounds();
				const positions = markers.map(a => a.position);
				this.mapInstance.fitBounds(createBoundsFromLatLngArray(positions));
				this.resetOverview$.next(false);
			} else {
				this.restoreOverviewRememberedBounds();
			}
		}
	}

	/**
	 * Higher-level function that locks bounds based on a set of markers.
	 * @param markers the markers we want to lock our view to
	 */
	private _lockBoundsToMarkers(markers: CustomGoogleMapMarker[]) {
		if (this.mapInstance) {
			const positions = markers.map(a => a.position);
			this.lockBoundsTo(createBoundsFromLatLngArray(positions));
		}
	}

	/**
	 * Provides the controller with a reference to the GoogleMap that is loaded in a component, to enable the controller to do updating of the Map in the background based in internal state.
	 * @param mapInstance the GoogleMap we want to render onto.
	 */
	public setMapInstance(mapInstance: GoogleMap) {
		this.mapInstance = mapInstance;
		this.connectEventListenersToMapInstance(mapInstance);
		return this;
	}

	/**
	 * Barrel function that is used to attach event listeners to a Google map.
	 * @param mapInstance the map instance whose events we want to observe
	 */
	public connectEventListenersToMapInstance(mapInstance: GoogleMap) {
		this.mapSubscription.add(this._mapDragendListener(mapInstance));
	}

	private _mapDragendListener(mapInstance: GoogleMap) {
		return mapInstance.mapDragend.subscribe(() => {
			const bounds = this._boundsLimit;
			// In bounds, return.
			if (bounds.contains(mapInstance.getCenter())) return;
			// Out of bounds - Move back.
			const mapCenter = mapInstance.getCenter();
			let x = mapCenter.lng();
			let y = mapCenter.lat();
			const maxX = bounds.getNorthEast().lng();
			const maxY = bounds.getNorthEast().lat();
			const minX = bounds.getSouthWest().lng();
			const minY = bounds.getSouthWest().lat();

			if (x < minX) x = minX;
			if (x > maxX) x = maxX;
			if (y < minY) y = minY;
			if (y > maxY) y = maxY;

			mapInstance.panTo(new google.maps.LatLng(y, x));
		});
	}

	/**
	 * Stores the current bounds into a BehaviorSubject that we can later use to reset the map's value with
	 */
	public rememberOverviewCurrentBounds() {
		if (this.mapInstance) {
			this.mapCenterMap.next(this.mapInstance.getCenter());
			this.rememberedZoomMap.next(this.mapInstance.getZoom());

			// console.log('Remember map', this.rememberedZoomMap.value);
			// console.log(`Remember Map Zoom=${this.mapInstance.getZoom()}, Center=${this.mapInstance.getCenter()}`);
		}
	}

	public rememberHouseCurrentBounds() {
		if (this.mapInstance) {
			this.mapCenterHouse.next(this.mapInstance.getCenter());
			this.rememberedZoomHouse.next(this.mapInstance.getZoom());

			// console.log('Remember house');
			// console.log(`Remember House Zoom=${this.mapInstance.getZoom()}, Center=${this.mapInstance.getCenter()}`);
		}
	}

	public clearHouseRememberedBounds() {
		// console.log('Clear house center');
		this.mapCenterHouse.next(null);
	}

	/**
	 * Restores the map to fit to the bounds stored when calling `this.remeberCurrentBounds`.
	 */
	public restoreOverviewRememberedBounds() {
		if (this.mapInstance && this.mapCenterMap.value) {
			this.mapInstance.center = this.mapCenterMap.value;
			this.mapInstance.zoom = this.rememberedZoomMap.value;
		}
	}

	public restoreHouseRememberedBounds() {
		if (this.mapInstance && this.mapCenterHouse.value) {
			this.mapInstance.center = this.mapCenterHouse.value;
			this.mapInstance.zoom = this.rememberedZoomHouse.value;
		}
	}

	private lockBoundsTo(bounds: google.maps.LatLngBounds) {
		this._boundsLimit = bounds;
	}

	/**
	 * Hook that allows the controller to update the `GoogleMap` when changes are detected.
	 * Current behaviour:
	 *  - allows the controller to adapt the bounds of the map on changes.
	 */
	public updateMapOnChanges() {
		this.mapSubscription.add(
			combineLatest([this.visibleMarkers$, this.resetOverview$])
				.pipe(debounceTime(500), distinctUntilChanged())
				.subscribe(([markers, reset]) => {
					if (this.mapInstance) {
						// Always lock the bounds on the full range of the discoverable markers.
						if (this.mapType.value === MapType.Default) {
							this.mapCenterHouse.next(false);
							this.updateBoundsToMarkers(markers, reset);
						}
						if (
							this.mapType.value === MapType['Huis'] &&
							(this.mapCenterHouse.value === undefined || this.mapCenterHouse.value === false)
						) {
							this.mapCenterHouse.next(true);
							// If we view a house, or no 'last bounds' were found, update bounds to all discoverable markers.
							this.updateBoundsToMarkers(markers);
						}
					}
				})
		);
	}

	/**
	 * Hook that allows the controller to detect what house and tiltmeter to select.
	 * Changes can be propagated from the router state to update the Controller state based on route changes.
	 * @param houseId$ Stream containing the id of the house we're connecting the controller to.
	 * @param index$ Stream containing the index of the tiltmeter that's currently active.
	 */
	public connectHouse(houseId$: Observable<string>, index$: Observable<number>) {
		this.activeHouse$ = houseId$.pipe(
			withLatestFrom(this.allHouses$),
			distinctUntilChanged(),
			map(([id, houses]) => {
				const currHouse = houses.find(house => house.id === id);
				if (currHouse) {
					if (currHouse.alarmViewType !== undefined) {
						this.activeViewType.next(currHouse.alarmViewType);
					} else {
						this.activeViewType.next(currHouse.viewType);
					}
					this.drawPolygon.next(currHouse.polygoonWeergeven);
				}

				return currHouse;
			}),
			filter<Woning>(Boolean),
			shareReplay(1)
		);

		this.pointsForActiveHouse$ = houseId$.pipe(
			distinctUntilChanged(),
			switchMap(houseId => this.database.meetpuntenVoorWoningId$(houseId)),
			tap(markers => this.drawPolygonBetweenPoints(markers)),
			shareReplay(1)
		);

		this.sensorsForActiveHouse$ = this.pointsForActiveHouse$.pipe(
			mergeMap(tiltmeetpunten =>
				from(tiltmeetpunten).pipe(
					mergeMap(tiltmeetpunt => {
						return this.database.sensorenVoorWoningId$(tiltmeetpunt.metadata);
					}),
					take(tiltmeetpunten.length),
					toArray(),
					shareReplay(1)
				)
			)
		);

		this.activePoint$ = combineLatest([index$, this.pointsForActiveHouse$]).pipe(
			map(([index, markers]) => markers.find(marker => marker.index === index)),
			filter<TiltMeter>(Boolean),
			distinctUntilKeyChanged('id'),
			shareReplay(1)
		);

		this.activeSensor$ = combineLatest([
			index$,
			this.sensorsForActiveHouse$,
			this.database.permissions$,
			this.database.settings$,
		]).pipe(
			map(([index, markers, permissions, settings]) => {
				const sensor = markers.find(marker => marker.index === index);
				if (permissions.sensorPijlen && sensor) {
					this.drawPointArrows(sensor, settings.ROTATIE);
				}
				return sensor;
			}),
			filter<Sensor>(Boolean),
			distinctUntilKeyChanged('id'),
			shareReplay(1)
		);

		this.mapType.next(MapType['Huis']);

		return this;
	}

	/**
	 * Disconnects the `mapType` from the current `House` state and resets to it's `Default` state.
	 */
	public disconnectHouse() {
		this.housePolygon.setMap(null);
		this.mapType.next(MapType['Default']);
	}

	/**
	 * Nullifies the map instance and unsubscribes the listener that handles
	 * inner state changes. (`updateMapOnChanges()`).
	 */
	public disconnectMap() {
		this.mapInstance = null;
		this.mapSubscription.unsubscribe();
	}

	constructor(private database: DatabaseService, private geolocationService: GeolocationService) {}
}
