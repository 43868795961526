import { markerOpts, markerType, CustomGoogleMapMarker, AlarmLevel } from 'app/config/google-maps.config';
import { TiltWoning } from 'shared';

export class Woning implements CustomGoogleMapMarker {
	familieNaam = '';
	huisNummer = '';
	id = '';
	position = new google.maps.LatLng(0, 0);
	straatNaam = '';
	plaats = '';
	markerType = 'woning' as markerType;
	options = markerOpts.woning;
	viewType = 1;
	alarmViewType = 1;
	alarmLevel = AlarmLevel.empty;
	polygoonWeergeven = false;
	controleSensoren = false;

	constructor(input: TiltWoning) {
		this.familieNaam = input.familieNaam;
		this.huisNummer = input.huisNummer;
		this.id = `${input.postCode}${input.huisNummer}`;
		this.position = new google.maps.LatLng(input.lat, input.lng);
		this.straatNaam = input.straatNaam;
		this.plaats = input.plaats;
		this.viewType = input.viewType;
		this.alarmViewType = input.alarmViewType !== undefined ? input.alarmViewType : input.viewType;
		this.alarmLevel = AlarmLevel[input.alarmLevel];
		this.polygoonWeergeven = input.polygoonWeergeven;
		this.controleSensoren = input.controleSensoren;
	}
}
