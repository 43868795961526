import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DatabaseService } from '@core/services/database/database.service';

@Injectable({
	providedIn: 'root',
})
export class DashboardGuard implements CanActivate {
	constructor(private readonly database: DatabaseService, private router: Router) {}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		return this.database.permissions$.pipe(
			map(permission => permission.alarmDashboard),
			tap(canActivate => {
				if (!canActivate) {
					this.router.navigate(['map']);
				}
			})
		);
	}
}
