import { HttpClient, HttpClientModule } from '@angular/common/http';
// Deze import is om de applicatie's LOCALE_ID (voor geld, datumformattering etc) op 'nl' te kunnen zetten.
import '@angular/common/locales/global/nl';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env';
import { MarkdownModule } from 'ngx-markdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NavbarRouteComponent } from './layout/navbar/navbar-route/navbar-route.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { OnboardingModule } from './modules/onboarding/onboarding.module';
import { ForgotpasswordComponent } from './routes/forgotpassword/forgotpassword.component';
import { LoginComponent } from './routes/login/login.component';
import { RegisterComponent } from './routes/register/register.component';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [
		AppComponent,
		NavbarComponent,
		NavbarRouteComponent,
		LoginComponent,
		ForgotpasswordComponent,
		RegisterComponent,
	],
	imports: [
		BrowserModule,
		CoreModule,
		SharedModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MarkdownModule.forRoot({ loader: HttpClient }),
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		OnboardingModule,
		NgbModule,
	],
	providers: [{ provide: LOCALE_ID, useValue: 'nl' }],
	bootstrap: [AppComponent],
})
export class AppModule {}
