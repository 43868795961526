import { AlarmLevel, CustomGoogleMapMarker, markerOpts, markerType } from 'app/config/google-maps.config';
import { TiltMeetpunt } from 'shared';

export class TiltMeter implements CustomGoogleMapMarker {
	huisId = '';
	id = '';
	index = 0;
	polygonIndex;
	markerType = 'meetpunt' as markerType;
	meetpuntNaam = '';
	muur = '';
	options = markerOpts['sensor/default'];
	position = new google.maps.LatLng(0, 0);
	extraInfo = '';
	metadata: TiltMeetpunt;
	yLineColor: string;
	xLineColor: string;
	alarmLevel = AlarmLevel.empty;
	controleSensor1: string;
	controleSensor2: string;

	constructor(input: TiltMeetpunt) {
		this.huisId = input.woningId;
		this.id = input.meetpuntId;
		this.index = input.indexRondrekening;
		this.polygonIndex = input.indexPolygoon;
		this.meetpuntNaam = input.meetpuntNaam;
		this.muur = input.muur;
		this.position = new google.maps.LatLng(input.meetpuntLat, input.meetpuntLng);
		this.extraInfo = input.extraInfo;
		this.metadata = input;
		this.yLineColor = input.yLineColor;
		this.xLineColor = input.xLineColor;
		this.alarmLevel = AlarmLevel[input.alarmLevel];
		this.controleSensor1 = input.controleSensor1 || '';
		this.controleSensor2 = input.controleSensor2 || '';
	}
}
