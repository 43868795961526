import { AlarmLevel, CustomGoogleMapMarker, markerOpts, markerType } from 'app/config/google-maps.config';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { TiltMeetpunt, TiltSensor } from 'shared';

export class Sensor implements CustomGoogleMapMarker {
	id = '';
	sensorNaam = '';
	sensorXAs = '';
	sensorYAs = '';
	sensorHoekX = 0;
	sensorHoekY = 0;
	position = new google.maps.LatLng(0, 0);
	markerType = 'sensor' as markerType;
	options = markerOpts['sensor/default'];
	// meetpunt: TiltMeetpunt;
	meetStart: any;
	meetEind: any;
	index: number;
	yLineColor: string;
	xLineColor: string;
	alarmLevel = AlarmLevel.empty;
	yAsMin: number;
	yAsMax: number;
	controleSensor1: string;
	controleSensor2: string;

	constructor(input: TiltSensor) {
		if (input) {
			this.id = input.sensorId;
			this.sensorNaam = input.sensorNaam;
			this.position = new google.maps.LatLng(input.sensorLat, input.sensorLng);
			this.sensorXAs = input.sensorXAs;
			this.sensorYAs = input.sensorYAs;
			this.sensorHoekX = input.sensorHoekXAs;
			this.sensorHoekY = input.sensorHoekYAs;
			// this.meetpunt = input.meetpunt;
			this.meetStart = input.meetStart;
			this.meetEind = input.meetEind;
			this.index = input.index;
			this.yLineColor = input.yLineColor;
			this.xLineColor = input.xLineColor;
			this.alarmLevel = AlarmLevel[input.alarmLevel];
			this.yAsMin = input.yAsMin || 0;
			this.yAsMax = input.yAsMax || 0;
			this.controleSensor1 = input.controleSensor1 || '';
			this.controleSensor2 = input.controleSensor2 || '';
		}
	}
}
