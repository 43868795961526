import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { UserPositionMarker } from './user-position-marker.class';

@Injectable({
	providedIn: 'root',
})
export class GeolocationService {
	private geolocationSource = new Subject<Position>();
	geolocation$ = this.geolocationSource.asObservable().pipe(shareReplay(1));

	geolocationLatLng$ = this.geolocation$.pipe(
		map(loc => new google.maps.LatLng(loc.coords.latitude, loc.coords.longitude))
	);

	private trackingPermittedSource = new Subject<boolean>();
	trackingPermitted$ = this.trackingPermittedSource.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

	userPositionMarker$ = this.geolocation$.pipe(
		map(location => {
			return new UserPositionMarker(location.coords.latitude, location.coords.longitude);
		})
	);

	private deviceSupportsGeolocationSource = new Subject<boolean>();
	deviceSupportsGeolocation$ = this.deviceSupportsGeolocationSource
		.asObservable()
		.pipe(distinctUntilChanged(), shareReplay(1));

	/**
	 * Follows the device's location through the geolocation API.
	 */
	handleDeviceLocation() {
		if (navigator.geolocation) {
			this.deviceSupportsGeolocationSource.next(true);

			navigator.geolocation.watchPosition(
				position => {
					this.trackingPermittedSource.next(true);
					this.geolocationSource.next(position);
				},
				error => {
					if (error.code === error.PERMISSION_DENIED) {
						this.trackingPermittedSource.next(false);
					}
				}
			);
		} else {
			// This device does not support the geolocation API.
			this.deviceSupportsGeolocationSource.next(false);
		}
	}

	constructor() {
		this.handleDeviceLocation();
	}
}
