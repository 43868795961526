export const LINECHART_BORDER_COLOR_Y = '#109CF1';
export const LINECHART_BORDER_COLOR_X = '#f110e9';

export const LINECHART_BORDER_COLOR_OK = '#21BF21';
export const LINECHART_BORDER_COLOR_SIGNAL = '#E98C00';
export const LINECHART_BORDER_COLOR_INTERVENTION = '#D80404';
export const LINECHART_BORDER_COLOR_NO_DATA = '#0408D8';

export const LINECHART_BACKGROUND_COLOR = 'rgba(16, 156, 241, 0.2)';
export const LINECHART_DEFAULT_RANGE_DAYS = 7;

export const tiltLineChartOptions = {
	responsive: true,
	maintainAspectRatio: false,
	tooltips: {
		backgroundColor: 'rgba(25,42,62,0.6)',
		bodyFontColor: '#FFF',
		borderWidth: 0,
		caretSize: 0,
		colorBody: 'rgba(25,42,62,0.6)',
		bodyFontSize: 14,
		bodyFontStyle: 'bold',
		cornerRadius: 15,
		xPadding: 16,
		yPadding: 8,
		displayColors: false,
		enabled: true,
		intersect: false,
		titleFontSize: 10,
		titleFontStyle: 'regular',
		mode: 'nearest',
		callbacks: {
			title(tooltipItems) {
				return tooltipItems[0].xLabel;
			},
			label(tooltipItem) {
				return tooltipItem.yLabel + 'mm';
			},
		},
	},
	legend: { display: false },
	scales: {
		xAxes: [
			{
				type: 'time',
				ticks: {
					maxRotation: 90,
					minRotation: 90,
				},
				time: {
					displayFormats: {
						hour: 'MMM D HH:mm',
						day: 'MMM D',
						week: 'll',
					},
					stepSize: 3,
					unit: 'hour',
					tooltipFormat: 'MMM DD YYYY, HH:mm:ss',
				},
				gridLines: {
					display: false,
				},
			},
		],
		yAxes: [
			{
				gridLines: {
					borderDash: [8, 4],
				},
				ticks: {
					stepSize: 1,
				},
			},
		],
	},
};
